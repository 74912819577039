<nz-form-item>
  <nz-form-label
    *ngIf="label"
    [nzNoColon]="true"
    >{{ label }}</nz-form-label
  >
  <nz-form-control
    [nzErrorTip]="(errorMessage$ | async) ?? ''"
    [nzValidateStatus]="(validationStatus$ | async) ?? ''"
  >
    <nz-input-group
      class="multi-select-input__group"
      nz-dropdown
      [nzSuffix]="suffixTmpl"
      [nzDropdownMenu]="menu"
      [(nzVisible)]="menuVisible"
      [nzTrigger]="'click'"
      [nzClickHide]="false"
    >
      <div class="selected-values-list">
        <div
          class="selected-value"
          *ngIf="selectedValues.length === 0 && selectedNoneLabel"
        >
          <span class="selected-value__name">{{ selectedNoneLabel }}</span>
        </div>
        <div
          class="selected-value"
          *ngFor="let selected of selectedValues"
        >
          <span class="selected-value__name">{{ selected.label }}</span>
          <i
            nz-icon
            class="clickable"
            nzType="icons:close"
            (click)="removeSelectedOption(selected, $event)"
          ></i>
        </div>
        <input
          class="multi-select-input__input"
          nz-input
          [placeholder]="selectedValues.length ? '' : placeholder"
          (keydown)="onInputKeydown($event)"
          [ngModel]="search"
          (ngModelChange)="onSearchChange($event)"
        />
      </div>
    </nz-input-group>

    <ng-template #suffixTmpl>
      <div
        class="suffix-icon"
        [class.suffix-icon--opened]="menuVisible"
      >
        <ng-container *ngIf="!suffixIcon; else customSuffixIcon">
          <i
            *ngIf="!selectedValues.length"
            nz-icon
            nzType="pause"
            nzTheme="outline"
            [nzRotate]="90"
          ></i>
        </ng-container>

        <ng-template #customSuffixIcon>
          <i
            *ngIf="!selectedValues.length"
            nz-icon
            [nzType]="suffixIcon"
          ></i>
        </ng-template>

        <i
          nz-icon
          class="clickable"
          nzType="icons:close"
          *ngIf="selectedValues.length"
          (click)="clear()"
        ></i>
      </div>
    </ng-template>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul
        class="multi-select-input__dropdown-menu"
        nz-menu
      >
        <li
          *ngFor="let option of options | filterOptions: search"
          class="clickable"
          nz-menu-item
          (click)="onOptionToggle(option)"
        >
          <div class="multi-select-input__option">
            <div class="option-left">
              <i
                *ngIf="option.icon"
                class="option-icon"
                nz-icon
                [nzType]="option.icon"
              ></i>
              <span>{{ option.label }}</span>
            </div>
            <div class="option-right">
              <label
                nz-checkbox
                [ngModel]="selectedValuesMap[option.value]"
                (nzCheckedChange)="onOptionToggle(option)"
              ></label>
            </div>
          </div>
        </li>
      </ul>
    </nz-dropdown-menu>
  </nz-form-control>
</nz-form-item>
