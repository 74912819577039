import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectOption } from '@app/core/model/SelectOption';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Optional } from '@app/features/activations/model/Utils';

import { FilterOptionsPipeModule } from '../../../pipes/filter-options.pipe';
import { FormInputComponent } from '../form-input.component';
import { customValueAccessorFactory } from '../value-accessor.componet';

@Component({
  selector: 'app-multi-select-input',
  templateUrl: `multi-select-input.component.html`,
  styleUrls: ['multi-select-input.component.less'],
  providers: [customValueAccessorFactory(MultiSelectInputComponent)]
})
export class MultiSelectInputComponent extends FormInputComponent<string[]> implements OnChanges {
  @Input() public options!: SelectOption[];
  @Input() public selectedNoneLabel!: string;
  @Input() public suffixIcon: string;

  public selectedValues: SelectOption[] = [];
  public selectedValuesMap: Record<string, boolean> = {};
  public search?: string;
  public menuVisible = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && !changes.options.firstChange) {
      // Change value and emit event once options are set. Workaround for `onWriteValue` where options are not set yet
      this.setSelectedValue(this.value, changes.options.currentValue);
      this.setValue();
    }
  }

  public onInputKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Backspace':
        if (!this.search && this.selectedValues.length > 0) {
          const option = this.selectedValues[this.selectedValues.length - 1];
          this.selectedValues.splice(this.selectedValues.length - 1, 1);
          delete this.selectedValuesMap[option.value];
          this.setValue();
        }
        break;
    }
  }

  public onSearchChange(value: string): void {
    this.search = value;
    this.menuVisible = true;
  }

  public clear(): void {
    this.selectedValues = [];
    this.selectedValuesMap = {};
    this.setValue();
  }

  public onOptionToggle(option: SelectOption) {
    const existing = this.selectedValues.findIndex((value) => value.value === option.value);
    if (existing >= 0) {
      this.selectedValues.splice(existing, 1);
      delete this.selectedValuesMap[option.value];
    } else {
      this.selectedValues.push(option);
      this.selectedValuesMap[option.value] = true;
    }
    delete this.search;
    this.setValue();
  }

  public removeSelectedOption(option: SelectOption, event: MouseEvent): void {
    if (event) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    const index = this.selectedValues.findIndex((value) => value.value === option.value);
    this.selectedValues.splice(index, 1);
    delete this.selectedValuesMap[option.value];
    this.setValue();
  }

  protected onWriteValue(value: string[]) {
    super.onWriteValue(value);
    this.setSelectedValue(value, this.options);
  }

  private setValue(): void {
    this.value = this.selectedValues.map((value) => value.value);
    this.change(this.value);
  }

  private setSelectedValue(values: Optional<string[]>, options: SelectOption[]) {
    this.selectedValues = options.filter((option) => values?.includes?.(option.value));
    this.selectedValues.forEach((option) => (this.selectedValuesMap[option.value] = true));
  }
}

@NgModule({
  declarations: [MultiSelectInputComponent],
  exports: [MultiSelectInputComponent],
  imports: [
    NzFormModule,
    CommonModule,
    NzInputModule,
    NzIconModule,
    FormsModule,
    NzDropDownModule,
    NzCheckboxModule,
    FilterOptionsPipeModule
  ]
})
export class MultiSelectInputModule {}
