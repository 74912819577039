import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@app/core/model/SelectOption';
import { isMatchingString } from '@app/utils/string.utils';

@Pipe({
  name: 'filterOptions'
})
export class FilterOptionsPipe implements PipeTransform {
  public transform(options: SelectOption[], value: string | undefined): any {
    if (!value) {
      return options;
    }
    return options.filter((o) => {
      try {
        return isMatchingString(o.label, value);
      } catch (e) {
        return false;
      }
    });
  }
}

@NgModule({
  declarations: [FilterOptionsPipe],
  exports: [FilterOptionsPipe],
  imports: []
})
export class FilterOptionsPipeModule {}
